"use client";

import ErrorPageContent from "@/components/home/ErrorPageContent";

export default function NotFound() {
  return (
    <ErrorPageContent
      title="404 Not Found"
      detail="Could not find requested resource"
    />
  );
}
